import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

import Layout from "../components/layout";
import PageInfo from "../components/pageInfo";

const Contact = ({ data: { site, portrait } }) => {
  const pageTitle = "Contact Us";

  return (
    <Layout>
      <PageInfo title={pageTitle} />
      <h1 className="text-6xl text-center mb-10 font-new-tegomin">
        {pageTitle}
      </h1>

      <div className="flex flex-wrap">
        <div className="w-full md:w-1/2 md:pr-3">
          <div className="m-auto xl:ml-auto xl:mr-0" style={{ maxWidth: 600 }}>
            <GatsbyImage
              image={getImage(portrait)}
              alt="Portrait of Brian and Jenifer"
              style={{ maxWidth: 600 }}
              draggable="false"
            />
          </div>
        </div>
        <div className="w-full md:w-1/2 md:pl-3 mt-16">
          <div className="flex flex-col items-center md:items-start gap-16">
            <div
              className="flex flex-col items-center text-xl gap-4"
              style={{ maxWidth: 600 }}
            >
              <FontAwesomeIcon
                icon={faPhone}
                size="3x"
                style={{ maxWidth: 60 }}
              />
              <span className="text-center">
                If you have any questions or would like to place your order over
                the phone, give us a ring from
                5&#8209;8pm&nbsp;Central&nbsp;Time
                <span style={{ verticalAlign: "super", fontSize: "60%" }}>
                  1
                </span>
              </span>
              <a href={`tel:${site.siteMetadata.phone}`}>
                {site.siteMetadata.phoneHuman}
              </a>
            </div>

            <div
              className="flex flex-col items-center text-xl gap-4"
              style={{ maxWidth: 600 }}
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                size="3x"
                style={{ maxWidth: 60 }}
              />
              <span className="text-center">
                You may also send us an email with any questions or feedback on
                how we can make our store and services better
              </span>
              <span>
                <a
                  href={`mailto:${site.siteMetadata.email}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {site.siteMetadata.email}
                </a>
              </span>
            </div>
          </div>
        </div>
        <div className="w-full mt-10">
          <p className="text-sm leading-tight text-center">
            1: As we both work full time jobs, if there is no answer, please be
            sure to leave a message with your question.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        email
        phone
        phoneHuman
      }
    }
    portrait: file(relativePath: { eq: "brian_jen.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, quality: 40)
      }
    }
  }
`;

export default Contact;
